<template>
  <div>
    <h2 class="mb-4">Словарь</h2>

    <definition-filters class="mb-5" :table-params="tableParams" @change-field="changeField" />

    <data-table ref="dataTable" url="definitions" :headers="headers" :table-params="tableParams">
      <template v-slot:[`item.url`]="{ item: definition }">
        <a :href="createFullLink(definition.url)" target="_blank">{{ definition.url }}</a>
      </template>

      <template v-slot:[`item.isIndexable`]="{ item: definition }">{{ definition.isIndexable | yesNo }}</template>

      <template v-slot:[`item.actions`]="{ item: definition }">
        <nobr>
          <v-btn class="mr-2" icon color="accent" :to="{ name: 'definitions-id', params: { id: definition.id } }">
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>

          <v-btn icon color="error" @click="deleteDefinition(definition)">
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
        </nobr>
      </template>
    </data-table>
  </div>
</template>

<script>
import { Definition } from "~/libs/definition/definition";
import { ApiValidationError } from "~/core/api-validation-error";

import { editorForm } from "~/mixins/multi-editor/editor-form";

import DefinitionFilters from "~/components/definitions/index/filters";

export default {
  mixins: [editorForm],
  data() {
    return {
      headers: [
        { text: "id", value: "id" },
        { text: "Термин", value: "term" },
        { text: "URL", value: "url" },
        { text: "Индексация", value: "isIndexable" },
        { text: "Действия", value: "actions", sortable: false }
      ],

      tableParams: {
        url: "",
        term: ""
      }
    };
  },
  methods: {
    createFullLink(url) {
      return Definition.createFullUrl(url);
    },

    async deleteDefinition({ id }) {
      try {
        await this.$axios.$delete(`definitions/${id}`);
        await this.$refs.dataTable.update();

        this.$snackbar.success("Удалено");
      } catch (e) {
        const html = new ApiValidationError(e).toHtml();

        this.$snackbar.error("Не удалено" + html);
      }
    },

    changeField(payload) {
      this.commitChange(this.tableParams, payload);
    }
  },
  components: {
    DefinitionFilters
  }
};
</script>
