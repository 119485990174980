<template>
  <v-sheet elevation="3" class="pa-3">
    <v-row>
      <v-col cols="12" md="4">
        <v-text-field label="URL" :value="tableParams.url" clearable @input="setField('url', $event)" />
      </v-col>

      <v-col cols="12" md="4">
        <v-text-field label="Термин" :value="tableParams.term" clearable @input="setField('term', $event)" />
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import { editorFields } from "~/mixins/multi-editor/editor-fields";

export default {
  mixins: [editorFields],
  props: {
    tableParams: {
      type: Object,
      required: true
    }
  }
};
</script>
